<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal @success="getAll" />

        <ModalAddKey
            :modalAddKey="modalAddKey"
            @refresh="getAll"
            @close="modalAddKey = false"
        />

        <ModalEditKey
            v-if="modalEditKeyVisible"
            :modalEditKey="modalEditKey"
            :selectedKeyProp="selectedKey"
            @refresh="getAll"
            @close="modalEditKey = false"
            @closed="modalEditKeyVisible = false"
        />

        <ModalEditHolder
            v-if="modalEditHolderVisible"
            :modalEditHolder="modalEditHolder"
            :selectedKey="selectedKey"
            @refresh="getAll"
            @close="modalEditHolder = false"
            @closed="modalEditHolderVisible = false"
        />

        <ModalKeyHistory
            :keyUuid="selectedKey"
            :modalKeyHistory="modalKeyHistory"
            @close="modalKeyHistory = false"
        />

        <div class="flex items-end justify-between border-b border-gray-200 mb-3 pb-3">
            <div>
                <el-dropdown trigger="click" class="mr-2">
                    <el-button type="primary">
                        {{ $t('keys.menu') }} <i class="el-icon-arrow-down el-icon--right" />
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <router-link :to="{ name: 'keys_archived' }">
                            <el-dropdown-item>
                                {{ $t('keys.archived') }}
                            </el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>

                <el-button type="primary" :disabled="!userCan('create keys')" @click="addKey">
                    {{ $t('keys.add') }}
                </el-button>
            </div>
            <div class="flex items-center">
                <fa-icon v-show="$wait.is('keys.loading')" :icon="['fad', 'spinner']" spin class="text-2xl mr-3 opacity-75" />
                <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('common.search')" clearable />
            </div>
        </div>

        <KeysTable
            :data="keysData"
            @handleKeyHistory="handleKeyHistory"
            @handleEditHolder="handleEditHolder"
            @handleEdit="handleEdit"
            @handleArchive="handleArchive"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="keysData.length" waitKey="keys.loading" />
    </div>
</template>
<script>
export default {
    components: {
        ModalAddKey:     () => import(/* webpackChunkName: "Keys/ModalAddKey" */ './components/ModalAddKey'),
        ModalEditKey:    () => import(/* webpackChunkName: "Keys/ModalEditKey" */ './components/ModalEditKey'),
        ModalEditHolder: () => import(/* webpackChunkName: "Keys/ModalEditHolder" */ './components/ModalEditHolder'),
        ModalKeyHistory: () => import(/* webpackChunkName: "Keys/ModalKeyHistory" */ './components/ModalKeyHistory'),
        KeysTable:       () => import(/* webpackChunkName: "Keys/KeysTable" */ './components/KeysTable'),
    },

    data() {
        return {
            keysData: [],
            keysDataOrigin: [],

            quickSearch: '',
            selectedKey: null,

            modalEditHolderVisible: false,
            modalEditHolder:        false,
            modalEditKey:           false,
            modalEditKeyVisible:    false,
            modalAddKey:            false,
            modalKeyHistory:        false,
        };
    },

    computed: {
        keysDataForTable() {
            return this.keysData;
        },
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('keys.keys'));
        this.$store.commit('setActiveMenuItem', '1-3');
        this.getAll();
    },

    methods: {
        async getAll() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/keys`);
            this.keysData = data;
            this.keysDataOrigin = data;
            // this.$store.dispatch('keys/getAll');
        },

        addKey() {
            this.modalAddKey = true;
        },

        async handleEditHolder(scope) {
            this.selectedKey = scope.row;
            this.modalEditHolderVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalEditHolder = true;
        },

        async handleEdit(scope) {
            this.selectedKey = scope.row;
            this.modalEditKeyVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalEditKey = true;
        },

        handleKeyHistory(keyUuid) {
            this.selectedKey = keyUuid;
            this.modalKeyHistory = true;
        },

        handleArchive(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl: `${process.env.VUE_APP_CORE_API_URL}/clients/keys/${uuid}`,
            });
        },

        filter() {
            const search = this.quickSearch.toLowerCase();
            this.keysData = this.keysDataOrigin.filter(key => (
                    key.name.toLowerCase().includes(search)
                    || key.description?.toLowerCase().includes(search)
                    || key.client.toLowerCase().includes(search)
                    || key.address.toLowerCase().includes(search)
                    || key.holder.toLowerCase().includes(search)
            ));
        },

    },
};
</script>
<style scoped>
    .el-input{
        width: 18rem;
    }
</style>
